(function () {
  'use strict';

  const directive = { name: 'fillReqFields' };

  function controller() {
    function link(scope, element, attrs) {
      element[0].addEventListener('click', (e) => {
        scope.formData.profile_address = scope.profileFormData.address;
        scope.formData.profile_city = scope.profileFormData.city;
        scope.formData.profile_email = scope.profileFormData.email;
        scope.formData.profile_gender = scope.profileFormData.gender;
        scope.formData.profile_name = scope.profileFormData.name;
        scope.formData.profile_phone = scope.profileFormData.phone;
        scope.formData.profile_postcode = scope.profileFormData.postcode;
        scope.formData.profile_surname = scope.profileFormData.surname;
        scope.formData.accept_terms_and_conditions = scope.profileFormData.gdpr_data;
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
