import config from '../../app/service/configs/config.js';
import system$ from '../../app/service/rx/system$.js';
import { filter } from 'rxjs/operators';


(function () {
  const directive = { name: 'campobetSlider' };

  controller.$inject = ['config', 'system$', 'splash$' ];

  function controller(_config, _system$, _splash$) {
    function link(scope, element, attrs) {
      const videoWrap = document.querySelector('.main-banner-slider');
      const firstVideo = document.querySelector('#pre-video');
      const isSplashLoaded = document.body.classList.contains('loaded');
      let interval;
      const firstVideoEndedHandler = async () => {
        firstVideo.removeEventListener("ended", firstVideoEndedHandler);
        element[0].classList.add('loaded');
        videoWrap.classList.add('has-gradient');
        firstVideo.remove();
        await scope.spliderInstance.Components.Autoplay.play();
      }
      const videoPlayHandler = () => {
        firstVideo?.play();

        interval = setInterval(async () => {
          const progress = (firstVideo.currentTime / firstVideo.duration ) * 100;
          if(progress >= 10) {
            clearInterval(interval)
            element[0].classList.add('loaded');
            scope.spliderInstance.Components.Autoplay.play();
          }
        }, 200)
      }

      firstVideo.addEventListener("ended", firstVideoEndedHandler);


      if (typeof Splide !== 'function') {
        return;
      }

      scope.spliderInstance;

      const { collectionKey, config } = scope.$eval(attrs[directive.name]);
      const buildSlider = () => {
        if (isSplashLoaded) queueMicrotask(videoPlayHandler);
        scope.spliderInstance = new Splide(element[0], config);
        scope.spliderInstance.mount();
        scope.spliderInstance.Components.Autoplay.pause();
      }
      const destroySlider = async () => {
        scope.spliderInstance.destroy(true);
        scope.$watchCollectionspliderInstance = null;
      }

      const renewSlider = async () => {
        if (scope.spliderInstance) {
          await destroySlider();
        }

        buildSlider();
      }

      scope.$watchCollection(collectionKey, (collection) => {
        if (collection && collection.length) {
          renewSlider();
        }
      });

      _splash$
        .pipe(
          filter((m) => m === 'loaded')
        )
        .subscribe(videoPlayHandler);

      renewSlider();

      scope.$on('$destroy', () => {
        clearInterval(interval)
        destroySlider()
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
