(function () {

  'use strict';

  app.filter('dateToTimestamp', function () {

    return function (str) {
      return moment(str).toISOString();
    };
  })
})();
