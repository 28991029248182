import dkLimits from '../../app/service/dk/dk_limits.js';
import modal from '../../app/service/domain/modal.js';

(function () {
  'use strict';

  const directive = { name: 'cancelLimit' };

  controller.$inject = [ 'dkLimits', 'modal' ];

  function controller(_dkLimits, _modal) {
    function link( scope, element ) {
      element.bind('click', function(e) {
        _modal.open("selfexclude")
          .then(() => {
            _dkLimits.moneyLimit.cancel({
              type: scope.type,
              group: 'deposit'
            })
          })
      });
    }

    return {
      restrict: 'A',
      link
    };
  }
  app.directive( directive.name, controller );
})();
