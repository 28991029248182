import { gsap } from 'gsap';
import ScrollTrigger from '../../app/libs/gsap/ScrollTrigger.min.js';

(function () {
  'use strict';

  const directive = { name: 'campobetToggleCtaColor' };

  function controller() {
    const removeCta = (btn) => {
      btn?.classList.remove('btn-green');
      btn?.classList.add('btn-yellow');
    };

    const addCta = (btn) => {
      btn?.classList.add('btn-green');
      btn?.classList.remove('btn-yellow');
    };

    function link(scope, element, attrs) {
      gsap.registerPlugin(ScrollTrigger);

      const headerCta = document.querySelector('.header button.is-cta');
      const scrollerElement = document.querySelector('.page-wrapper__content');

      removeCta(headerCta);

      scope.scr = ScrollTrigger.create({
        trigger: element,
        scroller: scrollerElement,
        start: 'top top',
        end: 'bottom top',
        onLeave: () => addCta(headerCta),
        onLeaveBack: () => removeCta(headerCta),
      });

      scope.$on('$destroy', () => {
        scope.scr.kill(false);
        addCta(headerCta);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
