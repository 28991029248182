import 'moment'

(function () {

    'use strict';

    app.filter('dateUTCToLocal', function () {
        return function (str) {
            return moment.utc(str).local().toISOString();
        };
    })
})();
