import balanceHistoryService from './balance-history-service.js';
import { combineLatest } from 'rxjs';

(function () {
  'use strict';

  const DIRECTIVE_NAME = 'balanceHistoryFilterOption';

  controller.$inject = [
    'balanceHistoryService',
    '$translate'
  ];

  function controller(
    _balanceHistoryService,
    $translate
  ) {
    function link(
      $scope,
      $element,
      $attrs,
      $ctrl,
      $transclude,
    ) {
      if (!$scope.ngModel) $scope.ngModel = [];

      $element.empty()

      const container = $element[0];

      function buildOptionsGroup (filterOptions) {
        const container = document.createElement('div')
        container.classList.add('filter-option-group')

        for (const optGroup of filterOptions) {
          const optionsGroupTitle = document.createElement('p')
          optionsGroupTitle.classList.add('balance-filter__subtitle');

          const alias = document.createTextNode(
            $translate.instant(`filter.table.title.${optGroup.alias || 'default'}`)
          );
          const values = buildOptionsValues(optGroup)

          optionsGroupTitle.append(alias);
          container.append(optionsGroupTitle, values);
        }

        return container;
      }

      function buildOptionsValues (optGroup) {
        $scope.ngModel = {
          type: optGroup.type,
          status: [],
          transactionType: [],
        }

        const container = document.createElement('div')
        container.classList.add('balance-filter__options-group')

        for (const optValue of optGroup.values) {
          const hash = (Math.random() + 1)
            .toString(36)
            .substring(7);
          const checkbox = document.createElement('input')
          checkbox.setAttribute('type', 'checkbox');
          checkbox.setAttribute('id', `${optGroup.alias}-${optValue}-${hash}`)
          checkbox.setAttribute('value', optValue);
          checkbox.setAttribute('hidden', 'true');

          const label = document.createElement('label');
          label.setAttribute('for', `${optGroup.alias}-${optValue}-${hash}`);
          label.classList.add('balance-filter__btn')

          const labelText = document.createTextNode(optValue);

          checkbox.addEventListener(
            'change',
            ({
              target: {
                value,
                checked
              }
            }) => {
              $scope.$apply(() => {
                $scope.ngModel[optGroup.alias].toggle(value, checked);
              });
            });

          label.append(labelText);

          container.append(checkbox, label);
        }

        return container;
      }

      function buildFilterOptions (selectedFilter, filterOptions = []) {
        if (!selectedFilter) {
          $scope.ngModel.type = undefined;
          $scope.ngModel.status = [];
          $scope.ngModel.transactionType = [];
        }

        filterOptions = filterOptions
          .filter(element => element.type === selectedFilter)
          .reverse();

        const options = buildOptionsGroup(filterOptions)
        const exOptions = document.querySelector('.filter-option-group');

        exOptions?.remove();

        $transclude(
          $scope.$new(true),
          ($cloneElement, scope) => {
            const cloneElement = $cloneElement[0];

            cloneElement.append(options)

            $element.after(cloneElement)
          })
      }

      const subscription = combineLatest(
        _balanceHistoryService.selectedFilterType,
        _balanceHistoryService.filterOptions
      ).subscribe(([
                     selectedFilter,
                     options
                   ]) => buildFilterOptions(selectedFilter, options));
    }

    return {
      require: 'ngModel',
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(
    DIRECTIVE_NAME,
    controller
  );
})();
