import promo from '../../../app/service/domain/promo.js';

(function () {
  'use strict';

  const component = { name: 'campobetPromoList' };

  controller.$inject = ['$scope', 'promo'];

  function controller($scope, _promo) {
    $scope.collection = [];
    $scope.collectionCasino = [];
    $scope.collectionSport = [];

    this.$onChanges = function () {
      $scope.preloader = true;

      const o = {};
      if (this.category && this.category.length) o.category = this.category;
      if (this.codes && this.codes.length) o.trigger_codes = this.codes;
      if (this.count) o.count = this.count;

      _promo
        .list(o)
        .then((answer) => {
          $scope.collectionCasino = answer.result.filter(
            (promo) => promo.type === 'casino'
          );
          $scope.collectionSport = answer.result.filter(
            (promo) => promo.type === 'sport'
          );

          $scope.collection = answer.result;
        })
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  app.component( component.name, {
    controller: controller,
    template: app.getTU( component.name )
  });
})();

