(function () {

  'use strict';

  // button 'cancel' should be displayed on deposit limit form only if user has more than one set deplimit
  const directive = { name: 'campoDepositShowCancel' };

  controller.$inject = ['user'];

  function controller(_user) {

    function link( scope ) {
        let count = 0;

        count += _user.profile.limits.deposit.day.status === 0;
        count += _user.profile.limits.deposit.week.status === 0;
        count += _user.profile.limits.deposit.month.status === 0;

        scope.showLimits = count > 1;
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);

})();
