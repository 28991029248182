import state from '../../app/service/domain/state.js';
(function () {
  'use strict';

  const directive = { name: 'campobetSportBtn' };

  controller.$inject = ['state', '$state'];
  function controller(_state, $state) {
    function link(scope, element, attrs) {
      const { id } = scope.$eval(attrs[directive.name]);

      const virtuals = ['kiron_horses', 'kiron_dogs', 'kiron_table_tennis', 'vbi', 'vti', 'vbl', 'vfwc'];

      element[0].addEventListener('click', () => {
        if (virtuals.includes(id)) {
          _state.goto('virtual', { sportids: id });
        } else if (id === 'virtualSport') {
          _state.goto('virtual');
        } else {
          _state.goto('sport', { sportids: id });
        }
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
